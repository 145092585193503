////////// BACKGROUND COLOR //////////
.bg-primaryColor {
  background-color: rgba(255, 255, 255, 1);
}
.bg-secondaryColor {
  background-color: rgba(194, 172, 97, 1);
}
.bg-thirdColor {
  background-color: rgba(194, 172, 97, 1);
}
.bg-fourthColor {
  background-color: rgba(255, 255, 255, 1);
}
.bg-fifthColor {
  background-color: rgba(235, 235, 235, 1);
}
.bg-sixthColor {
  background-color: rgba(165, 46, 52, 1);
}
.bg-seventhColor {
  background-color: rgba(255, 255, 255, 1);
}
